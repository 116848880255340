<script>
import Fiat from "../components/Fiat.vue";
export default {
	name: 'FiatOnRamp',
	components: {
		Fiat,
	},
};
</script>

<template>
	<div class="container mx-auto mt-8">
		<Fiat/>
	</div>
</template>

<style scoped></style>
