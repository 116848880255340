<script>
import Govern from "../components/Govern.vue";
export default {
	name: 'Governance',
	components: {
		Govern,
	},
};
</script>

<template>
	<div class="container mx-auto mt-8">
		<Govern/>
	</div>
</template>

<style scoped></style>
